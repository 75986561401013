import React from "react";
import SuccessImg from "../assets/images/success.svg";
import { Footer } from "../components";
import { FETCH_URLS } from "../config";

const ThankYou = () => {
  const {docs} = FETCH_URLS;

  return (
    <>
      <div className="min-h-full -mb-[50px] pb-[50px] select-none">
        <section className="w-full">
          <div className="w-full xs:min-w-[440px] m-auto p-[27px] pb-0 xs:pb-[27px] text-center">
            <div className="inline">
              <img
                className="max-w-[386px] max-h-[40px] mx-auto xs:max-h-[60px]"
                src="assets/images/logos/miracl.svg"
                alt="MIRACL IAM Portal"
              />
            </div>
            <div className="mx-[10px] mt-[10px]">
              <div
                className="text-[24px] font-thin overflow-ellipsis leading-[30px] whitespace-nowrap overflow-hidden select-text inline-block"
                title="MIRACL IAM Portal"
              >
                MIRACL IAM Portal
              </div>
            </div>
          </div>
        </section>
        <div className="pb-[30px]">
          <div className="max-w-[440px] mx-auto flex items-center justify-center mt-6 flex-col gap-3 text-center">
            <img src={SuccessImg} alt="" className="w-28 h-28" />
            <h3 className="text-4xl tracking-tight select-text">Thank You!</h3>
            <p className="text-base select-text">
              Please check your mail for further instructions on how to complete
              your account setup.
            </p>
            <p className="mt-5">
              For more insights on IAM Portal, visit <a href={docs} className="text-[#005581]">Docs</a>.
            </p>
            <hr className="hidden xs:block divide-y-2 divide-slate-800 w-full my-4" />
            <p className="mt-4 xs:mt-0">
              <span className="select-text">Having trouble?</span>{" "}
              <a
                href="https://miracl.com/contact-miracl/"
                className="text-[#005581]"
                id="miracl-support-link"
              >
                Contact Support
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ThankYou;
