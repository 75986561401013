import { Home, Success, SignIn } from "./containers";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SignIn/>} />
        <Route path='/sign-up' element={<Home />} />
        <Route path="/thank-you" element={<Success />} />
      </Routes>
    </Router>
  );
};

export default App;
