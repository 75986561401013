let namePrefix = "workspace";
const companyNameValidationRules = [
  // rules from https://docs.aws.amazon.com/awscloudtrail/latest/userguide/cloudtrail-s3-bucket-naming-requirements.html
  (v) => !!v || "Name is required",
  (v) =>
    (v && /^[\a-z\d\.\-]*$/.test(v)) ||
    `The ${namePrefix} name can contain only lower-case characters, numbers, periods, and dashes.`,
  (v) =>
    (v && /^[\a-z\d]/.test(v)) ||
    `The ${namePrefix} name must start with a lowercase letter or number.`,
  (v) => (v && !/\-$/.test(v)) || `The ${namePrefix} name can't end with a dash`,
  (v) =>
    (v && !/\./.test(v)) ||
    `The ${namePrefix} name can't have periods`,
  (v) =>
    (v && !/\.+\./.test(v)) ||
    `The ${namePrefix} name can't have consecutive periods`,
  (v) =>
    (v && !/\-+\.$/.test(v)) ||
    `The ${namePrefix} name can't end with dash adjacent to period`,
  (v) =>
    (v && !/^(?:(?:^|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d)){4}$/.test(v)) ||
    `The ${namePrefix} name can't be formatted as an IP address`,
  (v) =>
    (v && v.length >= 3 && v.length <= 55) ||
    `The ${namePrefix} name must be enrolled between 3 and 55 characters long.`
];

const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const Validations = { companyNameValidationRules, validateEmail };

export default Validations;
